import { useStaticQuery, graphql } from "gatsby";

export const useParentData = () => {
    const { allStrapiParentReview } = useStaticQuery(
        graphql`
        query parentquery{
            allStrapiParentReview{
                nodes{
                    heading
                    sub_heading
                    slug
                    show_on_allpage
                    thumbnail {
							url
						}
						video {
							... on STRAPI__COMPONENT_UPLOAD_VIDEO_UPLOAD_VIDEO {
								id
								strapi_id
								strapi_component
								upload_video {
									url
									name
								}
							}
							... on STRAPI__COMPONENT_VIDEO_LINK_VIDEO_LINK {
								id
								video_link
								video_name
								strapi_component
							}
						}
						
                }
            }
        }
        `
    )
    return allStrapiParentReview.nodes
}