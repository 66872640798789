import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import VideoPopup from "../components/commonComponent/videoPopup";
import Layout from "../components/layout.component";
import { useParentData } from "../hooks/parent-review";
import { useParentHeader } from "../hooks/parent-review-header";
import SEO from "../components/seo";
const SuccessStories = () => {
    const [showVideo, setShowVideo] = useState(false);
    const [videoDetails, setVideoDetails] = useState({
        url: "",
        name: "",
    });

    const onClickPlayVideo = (url, name) => {
        setVideoDetails({
            url: url,
            name: name,
        });
        setShowVideo(true);
    };
    const successStories = useParentData();
	const caseStudyHeader = useParentHeader();
	const successStoriesHeading = caseStudyHeader.filter((item) => item.page_type === "testimonials");
    const { heading} = successStoriesHeading[0] ?? "";

    return (
        <Layout>
            <SuccessStoriesStyled>
                <SuccessStoriesWrapperStyled>
					<div dangerouslySetInnerHTML={{ __html: heading ?? "" }} id="carousel_heading_1" />

                    <SuccessStoriesPeopleWrapperStyled>
                        {successStories
                            .filter((data) => data.show_on_allpage === true)
                            .map((data, index) => {
                                return (
                                    // <div className="good_university_people_card_details" key={index}>
                                    //     <div className="good_university_people_card_wrapper">
                                    //         <div
                                    //             className="good_university_people_card_img_wrapper"
                                    //             onClick={() =>
                                    //                 data?.video[0]?.strapi_component === "video-link.video-link"
                                    //                     ? onClickPlayVideo(
                                    //                         data?.video[0]?.video_link,
                                    //                         data?.video[0]?.video_name
                                    //                     )
                                    //                     : onClickPlayVideo(
                                    //                         data?.video[0]?.upload_video?.url,
                                    //                         data?.video[0]?.upload_video?.name
                                    //                     )
                                    //             }
                                    //         >
                                    //             <img src={data.thumbnail.url} alt="" />
                                    //             <FontAwesomeIcon icon={faPlay} size="1x" className="fa-play" />
                                    //         </div>
                                    //         <div className="good_university_people_bottom_details">
                                    //             <div className="good_university_people_left_bottom">
                                    //                 <small className="good_university_people_name">{data.heading}</small>
                                    //                 <small className="good_university_people_desgn">{data.sub_heading}</small>
                                    //             </div>
                                    //             {/* <small className="good_university_people_read_more">
									// 			Read More
									// 		</small> */}
                                    //         </div>
                                    //     </div>
                                    // </div>
									<div className="carousel-cell" key={data?.id}>
										<div
											className="carousel_card"
											aria-hidden="true"
											onClick={() =>
												data?.video[0]?.strapi_component === "video-link.video-link"
													? onClickPlayVideo(
														data?.video[0]?.video_link,
													)
													: onClickPlayVideo(
														data?.video[0]?.upload_video?.url,
													)
											}
										>
											<div className="carousel_image">
												<img src={data?.thumbnail?.url} alt="FeedBack" />
												<FontAwesomeIcon icon={faPlay} size="1x" className="fa-play" />
											</div>


											<div className="carousel_details">
												<span className="carousel_detail_name">{data?.heading ?? ""}</span>
												<span className="carousel_detail_desgn">{data?.sub_heading ?? ""}</span>
											</div>
										</div>
									</div>
                                );
                            })}
                    </SuccessStoriesPeopleWrapperStyled>
                    {/* <ViewAllButtonStyle>Load More</ViewAllButtonStyle> */}
                </SuccessStoriesWrapperStyled>
                {showVideo && videoDetails.url != "" && (
                    <VideoPopup setShowVideo={setShowVideo} link={videoDetails.url} name={videoDetails.name} />
                )}
            </SuccessStoriesStyled>
        </Layout>
    );
};

const SuccessStoriesPeopleWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5rem;
	width: 100%;
	margin-bottom: 3rem;
	flex-wrap: wrap;
	.carousel-cell {
		height: 25em;
		margin: 1em;
	}
	.carousel_card {
		background: #ffffff;
		box-shadow: 0px 4px 25px rgba(17, 58, 104, 0.15);
		border-radius: 16px;
		padding: 0.5em;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.75em;
		min-width: 16.4375em;
		max-width: 16.4375em;
		height: 23.3125em;
		margin-right: 1.5em;
		position: relative !important;
	}
	.carousel_image {
		border-radius: 12px;
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.carousel_image img {
		min-width: 100%;
		max-width: 100%;
		height: 18.125em;
		object-fit: cover;
	}
	.carousel_details {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		gap: 0.25em;
		padding-left: 0.5em;
	}
	.carousel_detail_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 24px;
		color: #2a264c;
	}
	.carousel_detail_desgn {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #8295ab;
	}
	@media(max-width:500px){
		.carousel-cell {
			height: 17em;
		}
		.carousel_card {
			min-width: 12.5em;
			max-width: 12.5em;
			height: 17.5em;
		}
		.carousel_detail_name {
			font-size: 1rem;
			line-height: 19px;
		}
		.carousel_detail_desgn {
			font-size: 0.75rem;
			line-height: 15px;
		}
		.carousel_image img {
			height: 13.5em;
		}
	}
`;
const SuccessStoriesWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 0.75rem;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2A264C;
		margin-bottom: 1rem;
		margin-top: 4rem;
	}
	& h1 > p {
		color: #2A264C;
	}
	#carousel_heading_1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}

	.good_university_people_info {
		font-size: 1rem;
		line-height: 19px;
		color: var(--dark-blue);
		width: 20em;
	}

	@media (max-width: 541px) {
		& h2 {
			font-size: 2.7rem;
		}
	}
	@media (max-width: 500px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#good_university_subheading {
			font-size: 1rem;
			line-height: 19px;
			font-weight: 500;
			margin-bottom: 3rem;
		}
	}

	@media (max-width: 355px) {
		& h2 {
			font-size: 1.5rem;
		}
	}

	@media (max-width: 315px) {
		& h2 {
			font-size: 1.3rem;
		}
		#good_university_subheading {
			font-size: 0.9rem;
		}
	}
`;

const SuccessStoriesStyled = styled.section`
	.good_university_people_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: var(--dark-blue);
	}
	.good_university_people_desgn {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 17px;
		color: #889db4;
	}
	.good_university_people_read_more {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #fc997c;
		margin-right: 1.5em;
		cursor: pointer;
	}

	.good_university_people_card_wrapper {
		background: #ffffff;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
		border-radius: 16px;
		min-width: 21em;
		max-width: 21em;
		height: 15.875em;
		padding: 0.5em;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0.75em;
	}
	.good_university_people_card_img_wrapper {
		border-radius: 12px;
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.good_university_people_card_img_wrapper img {
		min-width: 100%;
		max-width: 100%;
		height: 11.25em;
		object-fit: cover;
	}
	.good_university_people_bottom_details {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	.good_university_people_left_bottom {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		padding-left: 0.5em;
	}
	#good_university_people_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3.5rem;
		width: 100%;
		margin-bottom: 3rem;
		flex-wrap: wrap;
	}
	.fa-play {
		font-size: 3rem;
		color: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}
	@media (max-width: 390px) {
		.good_university_people_card_details {
			width: 100%;
		}
		.good_university_people_card_wrapper {
			min-width: 100%;
			max-width: 100%;
		}
		.good_university_people_card_img_wrapper {
			min-width: 100%;
			max-width: 100%;
		}
	}

	@media (max-width: 355px) {
		.good_university_people_card_wrapper {
			min-width: 18rem;
			max-width: 18rem;
		}
		.good_university_people_info {
			font-size: 0.8rem;
		}
		.good_university_people_read_more {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 315px) {
		.good_university_people_card_wrapper {
			min-width: 15rem;
			max-width: 15rem;
		}
		.good_university_people_read_more {
			margin-right: 0rem;
			font-size: 0.8rem;
		}
		.good_university_people_name {
			font-size: 0.8rem;
		}
		.good_university_people_desgn {
			font-size: 0.7rem;
		}
		.good_university_people_info {
			font-size: 0.7rem;
		}
	}
`;
const ViewAllButtonStyle = styled.button`
	padding: 1rem 3rem;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	color: var(--dark-blue);
	border: 1px solid var(--dark-blue);
	border-radius: 8px;
	outline: none;
	background-color: #ffffff;
	@media (max-width: 500px) {
		padding: 0.90625rem 1.75rem;
	}
`;
export const Head = () => {
	return (
		<SEO title="The Real School | Let's Join the Best Online School in India!"
			description="The best online classes for kids offer the best online learning for kids at their own pace and capabilities. The kids can grow their knowledge from the basics to everything with the best online school in India."
			keywords="courses for kids online learning for children virtual school in india"
		/>
	)
}

export default SuccessStories;
